<template>
    <div class="body">

    <section class="policy">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h5>Политика конфиденциальности SplitMyFunds</h5>
                    <p>Настоящая Политика конфиденциальности регулирует способ, которым SplitMyFunds собирает,
                        использует, поддерживает и раскрывает информацию, полученную от пользователей (каждый из
                        которых - «Пользователь») веб-сайта http:// splitmyfunds.com («Сайт»). Эта политика
                        конфиденциальности распространяется на Сайт и все продукты и услуги, предлагаемые SplitMyFunds.
                        Если у вас есть дополнительные вопросы или вам нужна дополнительная информация о нашей Политике
                        конфиденциальности, не стесняйтесь обращаться к нам.
                        Настоящая Политика конфиденциальности применяется только к нашей онлайн-деятельности и
                        действительна для посетителей нашего Сайта в отношении информации, которой они поделились и/или
                        собирают на splitmyfunds.com. Эта политика не применима к какой-либо информации, собираемой в
                        автономном режиме или по каналам, отличным от этого Сайта.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Информация, которую мы собираем</h5>
                    <p>Мы можем собирать личную идентификационную информацию от пользователей различными
                        способами, включая, помимо прочего, когда пользователи посещают наш сайт, регистрируются на сайте,
                        подписываются на информационный бюллетень, отвечают на опрос и в связи с другими действиями,
                        услугами, функциями или ресурсами, которые мы предоставляем на нашем Сайте.
                        При необходимости у пользователей могут быть запрошены имя и адрес электронной почты. Мы будем
                        собирать личную идентификационную информацию от пользователей, только если они добровольно
                        предоставят нам такую ​​информацию. Пользователи всегда могут отказаться предоставлять личную
                        идентификационную информацию, за исключением того, что это может помешать им участвовать в
                        определенных действиях, связанных с Сайтом.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Лог-файлы</h5>
                    <p>Splitmyfunds.com следует стандартной процедуре использования файлов журнала. Эти файлы регистрируют посетителей, когда они посещают веб-сайты.
                        Все хостинговые компании занимаются этим и являются частью аналитики хостинговых услуг. Информация, собираемая файлами журналов, включает адреса интернет-протокола (IP),
                        тип браузера, поставщика интернет-услуг (ISP), отметку даты и времени, страницы перехода/выхода и количество кликов.
                        Они не связаны с какой-либо информацией, позволяющей установить личность. Цель информации - анализ тенденций, администрирование сайта,
                        отслеживание движения пользователей на сайте и сбор демографической информации.
                        Мы можем собирать неличную идентификационную информацию о пользователях всякий раз, когда они взаимодействуют с нашим Cайтом.
                        Неличная идентификационная информация включаeт имя браузера, тип компьютера и техническую информацию о средствах подключения
                        пользователей к нашему сайту, таких как операционная система и используемые поставщики интернет-услуг, а также другую подобную информацию.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Как мы используем вашу информацию</h5>
                    <p>SplitMyFunds может собирать и использовать личную информацию пользователей для следующих целей:
                        - Улучшения обслуживания клиентов;
                        - Предоставляемая вами информация помогает нам более эффективно реагировать на ваши запросы в службу поддержки и поддержку;
                        - Чтобы персонализировать пользовательский опыт;
                        - Мы можем использовать информацию в совокупности, чтобы понять, как наши Пользователи как группа используют услуги и ресурсы, предоставляемые на нашем Сайте;
                        - Мы можем использовать адрес электронной почты для отправки им информации и обновлений, касающихся их заказа. Если Пользователь решит подписаться на наш список рассылки, он будет получать электронные письма, которые могут включать в себя новости компании, обновления, информацию о связанных продуктах или услугах. Если в любое время Пользователь захочет отказаться от получения электронных писем в будущем, он может это сделать это связавшись с нами через наш Сайт или службу поддержки.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Файлы cookie и веб-маяки</h5>
                    <p>Наш сайт может использовать файлы cookie для улучшения взаимодействия с пользователем. Веб-браузер пользователя размещает
                        файлы cookie на их жестком диске для ведения учета, а иногда и для отслеживания информации о них. Пользователь может
                        настроить свой веб-браузер так, чтобы он отказывался от файлов cookie или предупреждал вас об отправке файлов cookie.
                        Если они это сделают, обратите внимание, что некоторые части Сайта могут работать некорректно.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Как мы защищаем вашу информацию</h5>
                    <p>
                        Мы применяем соответствующие методы сбора, хранения и обработки данных и меры безопасности для защиты от несанкционированного
                        доступа, изменения, раскрытия или уничтожения вашей личной информации, имени пользователя, пароля, информации о транзакциях и
                        данных, хранящихся на нашем Сайте. Обмен конфиденциальными и частными данными между Сайтом и его Пользователями происходит по
                        защищенному каналу связи SSL, зашифрован и защищен цифровыми подписями.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Политика конфиденциальности рекламных партнеров</h5>
                    <p>Вы можете ознакомиться с этим списком, чтобы найти Политику конфиденциальности для каждого из рекламных партнеров splitmyfunds.com.
                        Сторонние рекламные серверы или рекламные сети используют такие технологии, как файлы cookie, JavaScript или веб-маяки, которые используются в их соответствующих рекламных объявлениях и ссылках, которые появляются на splitmyfunds.com и отправляются непосредственно в браузер пользователей. Когда это происходит, они автоматически получают ваш IP-адрес. Эти технологии используются для измерения эффективности их рекламных кампаний и / или для персонализации рекламного контента, который вы видите на посещаемых вами веб-сайтах.
                        Обратите внимание, что splitmyfunds.com не имеет доступа или контроля над этими файлами cookie, которые используются сторонними рекламодателями.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Политика конфиденциальности третьих лиц</h5>
                    <p>Политика конфиденциальности splitmyfunds.com не распространяется на других рекламодателей или веб-сайты. Таким образом, мы советуем вам ознакомиться с соответствующими Политиками конфиденциальности этих сторонних рекламных серверов для получения более подробной информации. Он может включать в себя их методы и инструкции о том, как отказаться от определенных вариантов.
                        Вы можете отключить файлы cookie в настройках вашего браузера. Более подробную информацию об управлении файлами cookie в определенных веб-браузерах можно найти на соответствующих веб-сайтах браузеров.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Передача вашей личной информации</h5>
                    <p>Мы не продаем, не обмениваем и не сдаем в аренду личную идентификационную информацию пользователей другим лицам. Мы можем передавать общую агрегированную демографическую информацию, не связанную с какой-либо личной идентификационной информацией о посетителях и пользователях, нашим деловым партнерам, доверенным аффилированным лицам и рекламодателям для целей, изложенных выше. Мы можем использовать сторонних поставщиков услуг, чтобы помочь нам управлять нашим бизнесом и Сайтом или управлять деятельностью от нашего имени, например рассылкой информационных бюллетеней или опросов. Мы можем передавать вашу информацию этим третьим лицам для этих ограниченных целей при условии, что вы дали нам свое разрешение.</p>
                </div>
                <div class="col-lg-12">
                    <h5>Изменения в этой политике конфиденциальности</h5>
                    <p>SplitMyFunds может по своему усмотрению обновить эту политику конфиденциальности в любое время. Когда мы это сделаем, мы опубликуем уведомление на главной странице нашего сайта, изменим дату обновления внизу этой страницы и отправим вам электронное письмо. Мы рекомендуем Пользователям часто проверять эту страницу на предмет изменений, чтобы оставаться в курсе того, как мы помогаем защитить личную информацию, которую мы собираем. Вы признаете и соглашаетесь с тем, что вы обязаны периодически просматривать эту политику конфиденциальности и узнавать об изменениях.</p>
                </div>
                <div class="col-lg-12">
                    <h5>Информация для детей</h5>
                    <p>Еще одна часть нашего приоритета - это защита детей при использовании Интернета. Мы призываем родителей и опекунов наблюдать, участвовать и/или контролировать и направлять их действия в Интернете.
                        Splitmyfunds.com сознательно не собирает личную информацию, позволяющую установить личность детей младше 13 лет. Если вы считаете, что ваш ребенок предоставил такую ​​информацию на нашем веб-сайте, мы настоятельно рекомендуем вам немедленно связаться с нами, и мы сделаем все возможное, чтобы незамедлительно удалите такую ​​информацию из наших записей.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Ваше согласие с этими условиями</h5>
                    <p>Используя этот Сайт, вы подтверждаете свое согласие с этой политикой. Если вы не согласны с этой политикой, пожалуйста, не используйте наш Сайт. Дальнейшее использование вами Сайта после публикации изменений в этой политике будет считаться вашим согласием с этими изменениями.</p>
                </div>
                <div class="col-lg-12">
                    <h5>Связаться с нами</h5>
                    <p>Если у вас есть какие-либо вопросы об этой Политике конфиденциальности, практике этого сайта или ваших отношениях с этим сайтом, пожалуйста, свяжитесь с нами с помощью путей ниже:</p>
                    <ul>
                        <li>Электронная почта: info@splitmufinds.com</li>
                        <li>ВКонтакте: https://vk.com/splitmyfunds</li>
                        <li>Facebook: https://www.facebook.com/splitmyfunds</li>
                        <li>Telegram: https://t.me/splitmyfunds</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    </div>
</template>

<script>
    export default {
        name: 'Policy',
        data() {
            return {}
        },
        methods: {},
    }
</script>

<style src="./css/style.css"></style>
